export default [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      auth: true,
    },
    component: () => import("modules/dashboard/views/DashboardPage"),
  },
];
