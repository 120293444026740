import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { isMatch, isBefore, parse, isAfter, startOfDay } from "date-fns";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

/* Custom rules */
extend("base64_mime_type", {
  validate: (value, formats) => {
    if (value && typeof value === "string") {
      const mime = value.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1].toLowerCase().trim();
      return formats.some((item) => item.toLowerCase().trim() === mime);
    }
    return true;
  },
  message: "O arquivo é inválido",
});

extend("date_format", {
  validate: (value, { format }) => isMatch(value, format),
  params: [
    "format",
  ],
  message: "data/hora inválida",
});

extend("min_date", {
  validate: (value, { date }) => {
    if (date === "undefined") {
      return isAfter(parse(value, "dd/MM/yyyy", new Date()), new Date("1900-01-01T00:00:00"));
    }
    const starDate = startOfDay(new Date(date));
    const inputDate = parse(value, "dd/MM/yyyy", new Date());
    const now = new Date();
    inputDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
    return isAfter(inputDate, starDate);
  },
  params: [
    "date",
  ],
  message: "data inferior não permitida",
});

extend("max_date", {
  validate: (value, { date }) => {
    const endDate = new Date(date);
    endDate.setHours(23, 59, 59);
    return isBefore(parse(value, "dd/MM/yyyy", new Date()), endDate);
  },
  params: [
    "date",
  ],
  message: "data superior ao permitido",
});

extend("required_html", {
  validate: (value) => {
    const element = document.createElement("div");
    element.innerHTML = value;
    const hasValue = !!`${element.textContent || element.innerText}`.trim();
    element.remove();
    return hasValue;
  },
  message: "Insira o conteúdo",
});
