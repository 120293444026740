export default {
  common: {
    logout: "Sair",
    enabled: "Ativo",
    disabled: "Inativo",
    actions: "Ações",
    noItems: "Sem itens para exibir",
    yes: "Sim",
    no: "Não",
    edit: "Editar",
    changeStatus: "Alterar o status",
    warning: "Alerta",
    add: "Adicionar",
    save: "Salvar",
    cancel: "Cancelar",
    search: "Pesquisar...",
    select: "Selecione",
    change: "Alterar",
    send: "Enviar",
    tapHere: "Digite ou copie o conteúdo aqui...",
    remove: "Remover",
    filter: "Filtros",
  },
  login: {
    subtitle: "Insira seus dados e clique em login para iniciar sua sessão.",
    enter: "Login",
    form: {
      username: "Nome de usuário",
      password: "Sua senha",
    },
    feedback: {
      error: {
        403: "Dados da empresa inativo. Entre em contado com o administrador.",
        401: "Falha ao efetuar o login. Por favor tente novamente.",
      },
    },
  },
  sidebar: {
    dashboard: "Dashboard",
    customers: "Usuários/Clientes",
    travels: "Viagens e roteiros",
    directives: "Diretrizes gerais",
  },
  dashboard: {
    cards: {
      trips: {
        title: "Viagens",
        subtitle: "Todas publicadas",
      },
      guidelines: {
        title: "Diretrizes",
        subtitle: "Diretrizes ativas",
      },
      users: {
        title: "Usuários/Clientes",
        subtitle: "Usuários ativos",
      },
      logins: {
        title: "Acessos",
        subtitle: "Últimos 7 dias",
      },
    },
  },
  trips: {
    table: {
      title: "Título",
      travelDestination: "Destino",
      start: "Início",
      end: "Fim",
      travelers: "Viajantes",
      status: "Status",
      actions: {
        travelScript: "Roteiro/Cronograma",
      },
    },
    form: {
      sections: {
        general: "Geral",
        guidelines: "Diretrizes gerais",
        files: "Anexos",
        financial: "Financeiro",
      },
      image: {
        title: "Imagem de capa",
        add: "Adicionar foto de capa",
        change: "Alterar foto de capa",
      },
      title: {
        label: "Título da viagem",
        placeholder: "Ex: Cruzeiro pela américa do sul",
      },
      description: {
        label: "Descrição",
      },
      travelers: {
        label: "Viajantes",
        placeholder: "Selecione ou digite",
      },
      start: {
        label: "Data de início",
        placeholder: "Selecione ou digite",
      },
      end: {
        label: "Fim da viagem",
        placeholder: "Selecione ou digite",
      },
      travelDestination: {
        label: "Destino da viagem",
        placeholder: "Ex: Orlando, Flórida, EUA",
      },
      guidelines: {
        label: "Diretrizes",
        placeholder: "Selecione ou digite",
      },
      price: {
        label: "Valor do Pacote",
        placeholder: "Ex: 5.200,00",
      },
      operator: {
        label: "Operadora",
        placeholder: "Ex: Latam",
      },
      paymentMethod: {
        label: "Forma de pagamento",
      },
      withInvoice: {
        label: "Emitir nota fiscal?",
      },
    },
    actions: {
      create: {
        feedback: {
          success: "Viagem adicionada com sucesso",
          error: "Falha ao salvar o cadastro de viagem. Por favor, tente novamente.",
        },
      },
      update: {
        feedback: {
          success: "Viagem atualizada com sucesso",
          error: "Falha ao atualizar a viagem. Por favor, tente novamente.",
        },
      },
      fetch: {
        feedback: {
          error: "Falha ao carregar os dados da viagem. Por favor, tente novamente.",
        },
      },
      createFlightEvent: {
        feedback: {
          success: "Evento de voo adicionado com sucesso",
          error: "Falha ao salvar o cadastro do voo. Por favor, tente novamente.",
        },
      },
      updateFlightEvent: {
        feedback: {
          success: "Evento de voo atualizado com sucesso",
          error: "Falha ao atualizar os dados do cadastro do voo. Por favor, tente novamente.",
        },
      },
      createPlaceEvent: {
        feedback: {
          success: "Local/Passeio adicionado com sucesso",
          error: "Falha ao salvar o cadastro do local/passeio. Por favor, tente novamente.",
        },
      },
      updatePlaceEvent: {
        feedback: {
          success: "Evento de local/passeio atualizado com sucesso",
          error: "Falha ao atualizar os dados do cadastro do local/passeio. Por favor, tente novamente.",
        },
      },
      dropEvent: {
        title: "Deseja excluir o evento selecionado?",
        feedback: {
          success: "Evento removido com sucesso",
          error: "Falha ao excluir o evento. Por favor, tente novamente",
        },
      },
      changeStatus: {
        title: "Selecione o novo status da viagem",
        items: {
          draft: "Rascunho",
          published: "Publicado",
          canceled: "Cancelado",
          archived: "Arquivado",
        },
        notify: "Notificar viajantes?",
        feedback: {
          success: "Status alterado com sucesso",
          error: "Falha ao alterar o status. Por favor, tente novamente",
        },
      },
    },
    script: {
      title: "Roteiro",
      noEvents: "Sem eventos registrados para esta viagem",
      dayCounter: "Dia {day}: {date}",
      add: {
        title: "Novo evento",
        flight: "Voo",
        place: "Local/Passeio",
      },
      forms: {
        place: {
          add: "Adicionar Local/Passeio",
          sections: {
            observations: {
              title: "Observações/Informações adicionais",
              noItems: "Sem informações adicionais",
              add: "Nova observação",
            },
            addressLine: {
              title: "Localização/Endereço",
            },
          },
          title: {
            label: "Título",
            placeholder: "Ex: Checkin Hotel Ubatuba",
          },
          description: {
            label: "Descrição",
            placeholder: "Checkin no hotel Ubatuba",
          },
          date: {
            label: "Data",
            placeholder: "05/06/2023",
          },
          start: {
            label: "Horário",
            placeholder: "10:00",
          },
          observations: {
            title: {
              label: "Titulo",
              placeholder: "Preço",
            },
            description: {
              label: "Descrição",
              placeholder: "120,00",
            },
          },
          location: {
            addressLine: {
              label: "Endereço",
              placeholder: "Ex: Rua Amapá, 12, Santa Etelvina",
            },
            postcode: {
              label: "Código postal/CEP",
              placeholder: "Ex: 69.093-151",
            },
            complement: {
              label: "Complemento",
              placeholder: "Ex: Prox ao shopping via norte",
            },
            city: {
              label: "Cidade",
              placeholder: "Ex: Manaus",
            },
            state: {
              label: "Estado/Província",
              placeholder: "Ex: Rio de Janeiro",
            },
            country: {
              label: "País",
              placeholder: "Ex: Brasil",
            },
          },
        },
        flight: {
          add: "Adicionar voo",
          edit: "Editar voo",
          addFlightConnection: "Adicionar voo de conexão",
          connections: "Conexões",
          title: {
            label: "Titulo/Descrição",
            placeholder: "Voo de Manaus para Recife",
          },
          identifier: {
            label: "Identificador",
            placeholder: "EX: LAO987",
          },
          date: {
            label: "Data do voo",
            placeholder: "Ex: 10/10/2023",
          },
          departureAirportCode: {
            label: "Aeroporto de sáida",
            placeholder: "MAO",
          },
          arrivalAirportCode: {
            label: "Aeroporto de chegada",
            placeholder: "BSB",
          },
          start: {
            label: "Horário de Saída",
            placeholder: "10:00",
          },
          end: {
            label: "Horário de Chegada",
            placeholder: "12:00",
          },
        },
      },

    },
  },
  users: {
    title: {
      add: "Adicionar novo usuário",
      edit: "Editar usuário",
    },
    table: {
      fullName: "Nome",
      username: "Usuário",
      email: "E-mail",
      birthday: "Nascimento",
      status: "Status",
      isAdmin: "Administrador",
    },
    passwordResetForm: {
      title: "Alteração/Reset de senha",
      subtitle: "A senha de acesso de {fullName}({username}) será alterada.<br>Um e-mail com os novos dados de acesso será enviado para <br><b>{email}</b>",
      fields: {
        logout: {
          label: "Encerrar sessão do usuário?",
        },
        password: {
          label: "Senha(Opcional)",
          placeholder: "*****",
        },
      },
    },
    form: {
      fullName: "Ex: José santos",
      username: "Ex: 02033531295",
      birthday: "Ex: 24/04/1997",
      email: "Ex: maria.mercedes@gmail.com",
    },
    actions: {
      changeStatus: {
        title: "Deseja alterar o status deste usuário?",
        feedback: {
          success: "Status do usúario alterado com sucesso",
          error: "Falha ao atualizar o status do usuário. Por favor, tente novamente",
        },
      },
      create: {
        feedback: {
          success: "Usuário criado com sucesso",
          error: "Falha ao salvar o cadastro de usuário. Por favor, tente novamente",
        },
      },
      update: {
        feedback: {
          success: "Usuário atualizado com sucesso",
          error: "Falha ao atualizar o cadastro do usuário. Por favor, tente novamente",
        },
      },
      passwordReset: {
        feedback: {
          success: "Senha alterada e enviada ao usuário",
          error: "Falha na alteração e/ou envio da senha ao usuário. Por favor, tente novamente.",
        },
      },
    },
  },

  guidelines: {
    title: {
      add: "Adicionar nova diretriz",
      edit: "Editar diretriz",
    },
    table: {
      title: "Título",
      content: "Conteúdo",
      status: "Status",
    },
    form: {
      title: "Ex: Documentos exigidos",
    },
    actions: {
      fetch: {
        feedback: {
          error: "Falha ao carregar os dados da diretriz",
        },
      },
      changeStatus: {
        title: "Deseja alterar o status desta diretriz?",
        feedback: {
          success: "Status da diretriz foi alterado com sucesso",
          error: "Falha ao atualizar o status da diretriz. Por favor, tente novamente",
        },
      },
      create: {
        feedback: {
          success: "Diretriz criada com sucesso",
          error: "Falha ao salvar o cadastro de diretriz. Por favor, tente novamente",
        },
      },
      update: {
        feedback: {
          success: "Diretriz atualizada com sucesso",
          error: "Falha ao atualizar a diretriz. Por favor, tente novamente",
        },
      },
    },
  },

  notFound: {
    title: "Ops!",
    content: "Página não encontrada.",
    action: "Voltar ao início",
  },
};
