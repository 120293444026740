<template>
    <ValidationProvider
        v-slot="{ errors }"
        :name="name || label.toLowerCase()"
        :vid="vid"
        :rules="rules"
        :mode="mode">
        <v-text-field
            v-model="modelValue"
            :maxlength="maxlength"
            outlined
            :append="icon"
            :loading="loading"
            :hide-details="hideDetails"
            :placeholder="placeholder"
            :label="label"
            :type="inputType"
            dense
            :prefix="prefix"
            :disabled="disabled"
            :error="!!errors[0]"
            :error-messages="errors[0]"
            @keypress="$emit('keypress', $event)"
            @blur="$emit('blur', $event)">
            <template #append>
                <div class="d-flex">
                    <v-icon
                        v-if="type === 'password'"
                        class="px-2"
                        @click="inputType = inputType === 'password' ? 'text' : 'password'">
                        {{ inputType === "password" ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                    <v-icon
                        v-if="icon"
                        class="py-4 pl-4 pr-2"
                        :color="hasError ? 'red' : 'primary'">
                        {{ icon }}
                    </v-icon>
                </div>
            </template>
            <template #prepend-inner>
                <slot name="prepend-inner" />
            </template>
        </v-text-field>
    </ValidationProvider>
</template>
<script>
export default {
  name: "BaseInput",
  props: {
    value: undefined,
    rules: undefined,
    vid: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
      default: "",
    },
    mode: {
      type: String,
      required: false,
      default: "aggressive",
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    maxlength: {
      type: [
        Number, String,
      ],
      required: false,
      default: "",
    },
    transform: {
      type: Function,
      default: (value) => value,
    },
  },
  data: (vm) => ({
    hasError: false,
    inputType: vm.type,
  }),
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", this.transform(value));
      },
    },
  },
};
</script>
