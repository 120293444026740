export default [
  {
    path: "/trips",
    name: "trips",
    meta: {
      title: "Viagens e roteiros",
      auth: true,
    },
    component: () => import("modules/trips/views/TripsPage"),
  },
  {
    path: "/trips/:id",
    name: "trips-form",
    meta: {
      title: "Viagens e roteiros",
      auth: true,
    },
    component: () => import("modules/trips/views/TripFormPage"),
  },
  {
    path: "/trips/:id/script",
    name: "trip-script",
    meta: {
      title: "Roteiro/Cronograma da viagem",
      auth: true,
    },
    component: () => import("modules/trips/views/TripScriptPage"),
  },
];
