import AuthService from "modules/auth/services/AuthService";

export default [
  {
    path: "/",
    name: "login",
    beforeEnter: (to, from, next) => {
      next((() => {
        if (AuthService.check()) {
          return { name: "dashboard" };
        }
        localStorage.clear();
        return {};
      })());
    },
    component: () => import("modules/auth/views/AuthPage"),
  },
];
