import VueRouter from "vue-router";
import AuthRoutes from "modules/auth";
import DashboardRoutes from "modules/dashboard";
import UserRoutes from "modules/users";
import GuidelineRoutes from "modules/guidelines";
import TripRoutes from "modules/trips";
import SupportRoutes from "modules/support";

export default new VueRouter({
  routes: [
    ...AuthRoutes,
    {
      path: "/termos",
      redirect: { name: "terms" },
      component: () => import("core/ui/templates/support"),
      children: SupportRoutes,
    },
    {
      path: "/download",
      name: "download",
      component: () => import("modules/download/views/DownloadPage"),
    },
    {
      path: "/dashboard",
      redirect: { name: "dashboard" },
      component: () => import("core/ui/templates/dashboard"),
      children: [
        ...DashboardRoutes,
        ...UserRoutes,
        ...GuidelineRoutes,
        ...TripRoutes,
      ],
    },
    {
      path: "/404",
      name: "notFound",
      component: () => import("core/ui/templates/errors/404"),
    },
    {
      path: "*",
      redirect: { name: "notFound" },
    },
  ],
  mode: "history",
  base: process.env.BASE_URL,
});
