import Vue from "vue";

class AuthService {
  static doLogin(username, password) {
    return Vue.auth.login({
      data: {
        username,
        password,
      },
    });
  }

  static check() {
    return Vue.auth.check();
  }
}

export default AuthService;
