<template>
    <v-menu
        ref="menu"
        v-model="menu"
        content-class="datepicker-menu"
        max-width="400"
        :close-on-content-click="false"
        offset-y>
        <template #activator="{ on, attrs }">
            <ValidationProvider
                v-slot="{ errors }"
                ref="datepicker"
                slim
                :rules="`date_format:dd/MM/yyyy${ '|min_date:'+min }${ max ?
                    '|max_date:'+max:'' }${required ? '|required' : ''}`"
                :name="name"
                :vid="vid">
                <v-text-field
                    v-bind="attrs"
                    :id="id"
                    v-model="maskedDate"
                    v-mask="'##/##/####'"
                    :name="name"
                    :label="label"
                    :placeholder="placeholder"
                    :error-messages="errors[0]"
                    :error="!!errors[0]"
                    dense
                    outlined
                    v-on="on">
                    <template
                        v-if="prependIcon"
                        #prepend-inner>
                        <v-icon>
                            {{ prependIcon }}
                        </v-icon>
                    </template>
                </v-text-field>
            </ValidationProvider>
        </template>
        <v-date-picker
            v-model="date"
            full-width
            :min="min"
            :max="max"
            no-title />
    </v-menu>
</template>
<script>
import { format, parseISO, parse } from "date-fns";

export default {
  name: "BaseDatePicker",
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    min: {
      type: String,
      required: false,
      default: undefined,
    },
    max: {
      type: String,
      required: false,
      default: undefined,
    },
    prependIcon: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "__/__/____",
    },
    id: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    vid: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },

  },
  data: (vm) => ({
    menu: false,
    maskedDate: Number.isInteger(parseISO(vm.value).getTime()) ? format(parseISO(vm.value), "dd/MM/yyyy") : "",
  }),
  computed: {
    date: {
      get() {
        const date = parseISO(this.value);
        return Number.isInteger(date.getTime()) ? format(date, "yyyy-MM-dd") : "";
      },
      set(value) {
        this.maskedDate = format(parseISO(value), "dd/MM/yyyy");
      },
    },
  },
  watch: {
    value(value) {
      this.menu = false;
      const date = parseISO(value);
      const maskedDate = Number.isInteger(date.getTime()) ? format(date, "dd/MM/yyyy") : "";
      if (maskedDate !== this.maskedDate) {
        this.maskedDate = maskedDate;
      }
    },
    maskedDate(value) {
      this.$nextTick(() => {
        this.$refs.datepicker.validate().then(({ valid }) => {
          if (valid) {
            this.$emit("input", (value ? format(parse(value, "dd/MM/yyyy", new Date()), "yyyy-MM-dd") : null));
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.datepicker-menu {
  &.v-menu__content {
    box-shadow: none;
  }
}
</style>
