import Vuetify from "vuetify/lib";
import pt from "vuetify/lib/locale/pt";

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#cc934f",
        secondary: "#9C27b0",
        accent: "#9C27b0",
        info: "#00CAE3",
      },
    },
  },
});
