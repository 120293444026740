import mutations from "./mutations";
import getters from "./getters";

export default {
  getters,
  mutations,
  namespaced: true,
  state: () => ({
    profile: {
    },
  }),
};
