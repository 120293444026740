import Vue from "vue";
import vuetify from "core/plugins/vuetify";
import VueMask from "v-mask";
import i18n from "core/plugins/i18n";
import store from "core/store";
import auth, { config } from "core/plugins/auth";
import VueCountryCode from "vue-country-code-select";
import VueSweetalert2 from "vue-sweetalert2";
import VMoney from "v-money";
import VueRouter from "vue-router";
import http from "core/plugins/axios";
import VueAxios from "vue-axios";
import Vuetify from "vuetify";
import VTiptap from "@peepi/vuetify-tiptap";

import router from "@/router";

import App from "@/App.vue";
import "core/ui/components";
import "core/plugins/vee-validate";
import "assets/scss/app.scss";

Vue.use(Vuetify);
Vue.use(VTiptap);

Vue.use(VueRouter);
Vue.use(VueCountryCode);
Vue.use(VMoney, { precision: 2 });
Vue.use(
  VueAxios,
  http,
);

Vue.use(auth, {
  plugins: {
    http,
    router,
  },
  ...config,
});
Vue.use(VueSweetalert2, {
  timer: 7000,
  confirmButtonColor: "#27a769",
  confirmButtonText: i18n.t("common.yes"),
  cancelButtonText: i18n.t("common.no"),
});
Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
