import auth from "@websanova/vue-auth/src/v2";
import driverAuthBearer from "@websanova/vue-auth/src/drivers/auth/bearer";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x";
import store from "@/core/store";

const config = {
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    notFoundRedirect: { name: "notFound" },
    stores: [
      "storage",
    ],
    authRedirect: { path: "/" },
    tokenDefaultKey: "access_token",
    loginData: {
      url: "auth/admin",
      method: "POST",
      redirect: "/dashboard",
      fetchUser: true,
      staySignedIn: true,
    },
    logoutData: {
      method: "DELETE",
      url: "auth",
      makeRequest: true,
    },
    fetchData: {
      url: "auth/me",
      method: "GET",
      enabled: true,
    },
    parseUserData: (user) => {
      store.commit("profile/SET_PROFILE", user);
      return user;
    },
  },
};

export default auth;
export { config };
