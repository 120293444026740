<template>
    <ValidationProvider
        v-slot="{ errors }"
        :vid="vid"
        :name="`${name?? label}`.toLowerCase()"
        :rules="rules">
        <v-tiptap
            v-bind="$props"
            :class="{'has-error': !!errors[0]}"
            :toolbar="toolbar"
            :rules="[]"
            v-on="$listeners" />
        <small
            v-if="!!errors[0]"
            class="red--text"
            v-text="errors[0]" />
    </ValidationProvider>
</template>

<script>
import { VTiptap } from "@peepi/vuetify-tiptap";

export default {
  name: "BaseEditor",
  extends: VTiptap,
  props: {
    rules: {
      type: [
        String, Object,
      ],
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    vid: {
      type: String,
      default: undefined,
    },
    toolbar: {
      type: Array,
      default: () => [
        "headings",
        "|",
        "bold",
        "italic",
        "underline",
        "strike",
        "clear",
        "color",
        "|",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "bulletList",
        "orderedList",
        "|",
        "link",
      ],
    },
  },
};
</script>

<style lang="scss">
.has-error {
  &.v-tiptap {
    .v-sheet--outlined {
      border-color: #f45b4f;
    }
  }
}
</style>
