import Vue from "vue";
import VueI18n from "vue-i18n";

import ptBR from "core/plugins/i18n/pt_BR";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "ptBR",
  fallbackLocale: "ptBR",
  messages: {
    ptBR,
  },
});
