export default [
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Usuários/Clientes",
      auth: true,
    },
    component: () => import("modules/users/views/UsersPage"),
  },
];
