export default [
  {
    path: "/guidelines",
    name: "guidelines",
    meta: {
      title: "Diretrizes/Informações",
      auth: true,
    },
    component: () => import("modules/guidelines/views/GuidelinesPage"),
  },
  {
    path: "/guidelines/:id",
    name: "guidelines-form",
    meta: {
      title: "Diretrizes/Informações",
      auth: true,
    },
    component: () => import("modules/guidelines/views/GuidelineFormPage"),
  },
];
