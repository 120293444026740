export default [
  {
    path: "/termos",
    name: "terms",
    meta: {
      title: "Termos e politicas",
    },
    component: () => import("modules/support/views/TermsPage"),
  },
  {
    path: "/contato",
    name: "contact",
    meta: {
      title: "Contato",
    },
    component: () => import("modules/support/views/ContactPage"),
  },
];
